<div class="container-fluid overflowInvisible" *ngIf="coinType !== 'Fiat'">
  <div class="row mt-2 mb-2">
    <div class="col-12 mb-2">
      <!--<span class="ml-2"><b>DEPOSIT - {{coinCode}}</b></span>-->
    </div>
    <div class="col-12" *ngIf="!showInvoiceInfo; else lightningInvoice">
      <div class="row">
        <div class="col-12 col-md-5 col-lg-4 col-xl-3 pr-lg-0 d-flex align-items-center justify-content-center">
          <div *ngIf="!isLightningNetwork()">
            <qrcode *ngIf="qrCode; else generateAddress" [qrdata]="qrCode" width="!!currencyInfo.cryptonote_address ? 200 : 140" errorCorrectionLevel="M">
            </qrcode>
            <ng-template #generateAddress>
              <ng-container *ngIf="!depositAddressSelected">
                <div class="col-12 px-0">
                  <button class="my-4 d-block mx-auto" mat-raised-button color="primary" (click)="generateAddressClick()">
                    <ng-container *ngIf="currencyInfo.cryptonote_address; then generatePID else generateWallet"></ng-container>
                    <ng-template #generatePID>
                      <ng-container *ngIf="currencyInfo.payment_memo_label; else noMemoLabel">{{currencyInfo.payment_memo_label | i18n}}</ng-container>
                      <ng-template #noMemoLabel i18n>Generate PaymentID</ng-template>
                    </ng-template>
                    <ng-template #generateWallet i18n>Generate Wallet Address</ng-template>
                  </button>
                </div>
              </ng-container>
            </ng-template>
          </div>
        </div>
        <div class="depositHolder col-12 col-md-7 col-lg-8 col-xl-9 mt-3 mt-lg-0">
          <div class="row">
            <!-- start coin network selection -->
            <div class="col-12 col-lg-6" [formGroup]="coinNetworkForm" *ngIf="currencyInfo.has_coin_networks === '1'">
              <div class="row align-items-center">
                <div class="col pr-0">
                  <mat-form-field class="col px-0 mx-0 my-0">
                    <mat-select [disabled]="coinNetworkOptions.length <= 1" i18n-placeholder placeholder="Network"
                      formControlName="coin_network" value="selectedCoinNetwork"
                      (selectionChange)="changeNetwork($event)">
                      <ng-container *ngIf="coinNetworkOptions.length > 0; else defaultNetwork">
                        <mat-option
                          *ngFor="let coin_network of coinNetworkOptions"
                          [value]="coin_network" [disabled]="coin_network.active === '0'"
                        >
                          {{ coin_network.network_name }}
                          <ng-container *ngIf="coin_network.active === '0'">
                            <span class="suspended-text ml-3 px-2">
                              SUSPENDED
                            </span>
                          </ng-container>
                        </mat-option>
                      </ng-container>
                      <ng-template #defaultNetwork>
                        <!-- show default network from parent component -->
                        <mat-option [value]="selectedCoinNetwork">
                          {{ selectedCoinNetwork.network_name }}
                        </mat-option>
                      </ng-template>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-auto">
                  <a
                    class="no-decoration" target="_blank"
                    href="https://support.chainex.io/support/solutions/articles/what-are-coin-networks"
                  >
                    <mat-icon
                      color="primary" class="col-auto d-flex mx-0 px-0" i18n-matTooltip matTooltipPosition="above"
                      matTooltip="This Coin is supported across multiple Chains. Please ensure that you select the same Chain
                      here as the Chain of the Wallet to which you are withdrawing. Withdrawing to the incorrect Chain will
                      result in your funds being lost. Click here for more information."
                    >
                      info
                    </mat-icon>
                  </a>
                </div>
              </div>

              <div class="col mx-0 px-0">
                <ng-container *ngIf="isLightningNetwork()">
                  <form [formGroup]="invoiceForm">
                    <mat-form-field class="col px-0 m-0">
                      <input
                        title="" type="number" name="Invoice amount" formControlName="amount" matInput required
                        [disabled]="invoiceForm.controls.amount.disabled" i18n-placeholder placeholder="Enter Amount"
                      >
                      <mat-error *ngIf="invoiceForm.controls.amount.hasError('required')" i18n>
                        Amount required.
                      </mat-error>
                      <mat-error *ngIf="invoiceForm.controls.amount.hasError('min') && !invoiceForm.controls.amount.hasError('required')" i18n>
                        Amount must be at least {{minAmount}}.
                      </mat-error>
                    </mat-form-field>

                    <div class="row col justify-content-between">
                      <span i18n>
                        Estimated: R{{ zarEstimate | decimalNumber: 2 }}
                      </span>
                    </div>

                    <mat-form-field class="col px-0 m-0">
                      <input title="" type="text" name="description" formControlName="description" i18n-placeholder placeholder="Description" matInput>
                    </mat-form-field>
                  </form>

                  <div class="col px-0 m-0 ng-star-inserted buttonContainer">
                    <button mat-raised-button color="primary" class="col-12 py-1" (click)="createInvoice()" i18n>
                      <strong>CREATE INVOICE</strong>
                    </button>
                  </div>
                </ng-container>
              </div>
            </div>
            <!-- end coin network selection -->

            <div class="col-12">
              <div class="row align-items-center" *ngIf="!isLightningNetwork()">
                <span class="col-12 depositAddress">
                  <b i18n>Deposit Address
                    <span *ngIf="currencyInfo.has_coin_networks === '1'" class="orange-text">
                      ({{selectedCoinNetwork.network_name}})
                    </span>
                  </b>
                </span>
                <div class="col-12 mt-0 mb-1">
                  <ng-container *ngIf="currencyInfo.cryptonote_address">
                    <div class="row mb-3 align-items-center">
                    <label class="col-auto mb-0 overflowWrap">{{currencyInfo.cryptonote_address}}</label>
                    &nbsp;
                    <mat-icon color="primary" ngxClipboard
                      (click)="copyToClipboard(currencyInfo.cryptonote_address)" i18n-matTooltip
                      matTooltip="Copy Address">file_copy</mat-icon>
                    </div>
                    <div class="PaymentAddress">
                      <div class="row align-items-center">
                        <ng-container *ngIf="currencyInfo.payment_memo_label; else noMemoLabel">
                          <span class="col-auto">
                            <b>{{currencyInfo.payment_memo_label}}</b>
                          </span>
                        </ng-container>
                        <ng-template #noMemoLabel><b i18n>Payment ID</b></ng-template>
                      </div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="depositAddressSelected; else generateAddressText">
                    <div class="row mx-0 mb-3 align-items-center">
                      <label class="col col-lg-auto pl-0 mb-0 overflowWrap">{{depositAddressSelected.address_address}}</label>
                      &nbsp;
                      <ng-container *ngIf="currencyInfo.cryptonote_address; else normalAddress">
                        <mat-icon color="primary" ngxClipboard
                          (click)="copyToClipboard(depositAddressSelected.address_address)" i18n-matTooltip
                          [matTooltip]="'Copy ' + (currencyInfo.payment_memo_label || 'Payment ID')">file_copy
                        </mat-icon>
                      </ng-container>
                    </div>
                    <ng-template #normalAddress>
                      <mat-icon color="primary" ngxClipboard
                        (click)="copyToClipboard(depositAddressSelected.address_address)" i18n-matTooltip
                        matTooltip="Copy Address">file_copy</mat-icon><br>
                    </ng-template>
                  </ng-container>

                  <ng-template #generateAddressText>
                    <div class="small-text clickable mb-3" mat-raised-button color="primary"
                      (click)="generateAddressClick()">
                      <ng-container *ngIf="currencyInfo.cryptonote_address; else normalAddress" i18n>
                        No {{ (currencyInfo.payment_memo_label || 'Payment ID') | i18n }} exists for this wallet.
                        Click to generate new {{ (currencyInfo.payment_memo_label || 'Payment ID') | i18n }}.
                      </ng-container>
                      <ng-template #normalAddress i18n>
                        No address currently exists for this wallet. Click to generate new wallet address.
                      </ng-template>
                    </div>
                  </ng-template>
                </div>

                <div class="col-12">
                  <div class="border-box pleaseDont">
                    <ng-container i18n *ngIf="selectedCoinNetwork && selectedCoinNetwork.contract_digits">
                      <span class="font-weight-bold">
                        Please ensure you are depositing {{coinCode}} under the contract address ending in
                        <ng-container *ngIf="selectedCoinNetwork.contract_link; else noContractLink">
                          <a href="{{selectedCoinNetwork.contract_link}}" target="_blank">{{selectedCoinNetwork.contract_digits}}</a>
                        </ng-container>
                        <ng-template #noContractLink>
                          {{selectedCoinNetwork.contract_digits}}
                        </ng-template>
                        <br><br>
                      </span>
                    </ng-container>
                    <ng-container *ngIf="currencyInfo.payment_memo_label" i18n>
                      Please ensure that the {{currencyInfo.payment_memo_label}} is used when making a deposit to
                      this wallet. ChainEX will be unable to allocate deposits to this wallet without it. <br>
                    </ng-container>
                    <ng-container *ngIf="['Ethereum', 'ERC20'].includes(currencyInfo.coin_type)" i18n>
                      <span class="font-weight-bold">
                        Please note that ChainEX only supports transactions on the {{ currencyInfo.coin_type }} network for this coin.
                        Deposits made for this coin from other chains will not be allocated to your wallet and will be irrecoverable. <br><br>
                      </span>
                    </ng-container>
                    <ng-container i18n>
                      Please do not mine directly to the deposit address and / or auto withdraw from pools.
                      Please aim to deposit at most five times an hour, exceeding this limit could
                      lead to your account being disabled.
                    </ng-container>
                    <ng-container *ngIf="showDepositLimitMessage.includes(coinCode)" i18n>
                      <br>A maximum of 9 999 9999 999 coins can be deposited at a time.
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngTemplateOutlet="autoTrade"></ng-container>

      <!-- Custom domain prompt -->
      <div class="col-12 mt-4 py-3 autotrade-border-box" *ngIf="showDomainPrompt">
        <div class="row align-items-center mx-0">
          <mat-icon class="dialog-close" (click)="dismissDomain()">close</mat-icon>
          <div class="col-auto pr-0">
            <img class="domain-icon" src="/assets/chainex/images/pages/balances/domainicon.png">
          </div>
          <div class="col">
            <span>Get a free domain from <a href="https://unstoppabledomains.com/" target="_blank">Unstoppable Domains</a> for this wallet address!
            </span>
          </div>
          <div class="col-12 col-md-6 col-lg-auto ng-star-inserted buttonContainer">
            <button
              class="col-12 col-lg-auto mx-0 mx-lg-3 mt-3 mt-lg-0"
              mat-raised-button color="primary" (click)="openDomainDialog()"
            >
              GET FREE DOMAIN
            </button>
          </div>
        </div>
      </div>
    </div>

    <ng-template #lightningInvoice>
      <div class="col-12">
        <div class="row w-100 justify-content-start">
          <a (click)="hideLightInvoice()" class="no-decoration cursor-pointer">
            <mat-icon class="">arrow_back_ios</mat-icon>
          </a>
        </div>
        <div *ngIf="invoicePaid; else invoiceDisplay" class="row w-100 justify-content-center">
          <div class="col-6 d-flex flex-column">
            <h5 class="py-1 mb-3 text-center">
              <strong>Invoice Payment Confirmed</strong>
            </h5>

            <span class="py-1 text-center" i18n *ngIf="!!invoice.value">
              <strong>{{ invoice.value }} {{ coinCode }}</strong>
            </span>

            <span class="py-1 text-center" i18n *ngIf="!!invoice.memo">
              <strong> {{ invoice.memo }}</strong>
            </span>

            <div class="d-inline-block py-1 my-3 mb-3 overflow-hidden text-center">
              <span class="text-tranculate invoice-address">
                {{ invoice.address }}
              </span>
              <mat-icon
                mat-raised-button i18n-matTooltip matTooltip="Copy Invoice"
                color="primary" (click)="copyToClipboardFiat(invoice.address, 'Invoice')"
              >
                file_copy
              </mat-icon>
            </div>

            <!-- <div class="col px-0 my-2 mx-0 ng-star-inserted buttonContainer">
              <mat-icon
                mat-raised-button i18n-matTooltip matTooltip="Copy Invoice"
                color="primary" (click)="copyToClipboardFiat(invoice.address, 'Invoice')"
              >
                file_copy
              </mat-icon>
            </div> -->
          </div>
        </div>
        <ng-template #invoiceDisplay>
          <div class="row w-100 justify-content-center" *ngIf="invoiceCreated">
            <div class="col-12 col-lg-6 d-flex flex-column">
              <h5 class="py-1 mb-0 text-center">
                <strong>Lightning Invoice:</strong>
              </h5>

              <div class="col my-3 d-flex justify-content-center">
                <qrcode [qrdata]="invoice.address" width="300" errorCorrectionLevel="M"></qrcode>
              </div>

              <span class="py-1 text-center" i18n *ngIf="!!invoice.value && invoice.value > 0">
                <strong>
                  Please pay <br>
                  {{ invoice.value }} {{ coinCode }}
                </strong>
              </span>

              <span class="py-1 text-center" i18n *ngIf="!!invoice.memo">
                <strong> {{ invoice.memo }}</strong>
              </span>

              <div class="d-inline-block py-1 my-3 overflow-hidden text-center">
                <span class="text-tranculate invoice-address">
                  {{ invoice.address }}
                </span>
                <mat-icon
                  mat-raised-button i18n-matTooltip matTooltip="Copy Invoice"
                  color="primary" (click)="copyToClipboardFiat(invoice.address, 'Invoice')"
                >
                  file_copy
                </mat-icon>
              </div>
            </div>
          </div>
          <div class="row py-5 w-100 justify-content-center" *ngIf="!invoiceCreated">
            <mat-progress-spinner mode="indeterminate" color="primary"></mat-progress-spinner>
          </div>
        </ng-template>
      </div>
    </ng-template>
  </div>
</div>

<div class="mt-4 mb-4 overflowInvisible" *ngIf="coinType === 'Fiat' && showDetails">
  <span><b i18n>Deposit Details</b></span>
  <div class="border-box mt-2" i18n>
    To deposit ZAR to your ChainEX account, simply EFT your desired amount directly from your personal bank account to ChainEX’s Standard Bank account provided below.
    Once the deposit has cleared we will allocate the received funds to your ChainEX account. To learn more about how the deposit process works,
    <a href="https://support.chainex.io/kb/tutorials/how-do-i-deposit-zar" target="_system">click here</a>
  </div>
  <div class="container-fluid mt-2 mb-4">
    <div class="row space-items">
      <div class="col-12 col-lg-6">
        <div class="row">
          <div class="border-box max-width mt-2" i18n>
            <div class="row center-items">
              Deposits may be made to our Standard Bank account using the banking details below:
            </div>
          </div>
          <mat-tab-group dynamicHeight mat-stretch-tabs class="max-width">
            <mat-tab label="Standard Bank">
              <div class="ml-2 t-14">
                <div class="row">
                  <div class="col-4 col-lg-6 mt-2 mb-2 fieldname" i18n>
                    Name:
                  </div>
                  <div class="col-6 mt-2 mb-2">
                    {{ bankSTD.holder }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-4 col-lg-6 mt-2 mb-2 fieldname" i18n>
                    Bank:
                  </div>
                  <div class="col-6 mt-2 mb-2">
                    {{ bankSTD.name }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-4 col-lg-6 mt-2 mb-2 fieldname" i18n>
                    Number:
                  </div>
                  <div class="col-6 mt-2 mb-2">
                    {{ bankSTD.account }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-4 col-lg-6 mt-2 mb-2 fieldname" i18n>
                    Branch:
                  </div>
                  <div class="col-6 mt-2 mb-2">
                    {{ bankSTD.branch }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-4 col-lg-6 mt-2 mb-2 fieldname" i18n>
                    Type:
                  </div>
                  <div class="col-6 mt-2 mb-2">
                    {{ bankSTD.type }}
                  </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="row">
          <div class="border-box max-width mt-2">
            <div class="row center-items" (click)="copyToClipboardFiat(depositRef, 'Reference')">
              <ng-container *ngIf="depositRef; else loadingRef">
                <div class="text-bold" i18n>
                  Your deposit reference:
                </div>
                <div class="ml-4" >
                  {{ depositRef }}
                  <mat-icon
                    class="copy-icon" color="primary" ngxClipboard
                    i18n-matTooltip matTooltip="Copy Reference"
                  >
                    file_copy
                  </mat-icon>
                </div>
              </ng-container>
              <ng-template #loadingRef>
                  <mat-progress-spinner mode="indeterminate" color="primary"></mat-progress-spinner>
              </ng-template>
            </div>
          </div>
          <div class="mt-4">
            <ul class="t-14">
              <li *ngIf="interUserLevel1" i18n>
                <strong>
                  If a ZAR deposit is done while you are on level 1, you will be blocked from performing any digital asset withdrawals until you have completed 2 verification
                </strong>
              </li>
              <li i18n>Please ensure that you use your unique reference when depositing</li>
              <li i18n>If the incorrect reference is used, you will be charged an additional administrative fee to allocate your deposit</li>
              <li i18n>ChainEX will only allocate deposits received from a bank account registered to your name</li>
              <li il8n>Please note that banking charges incurred on ZAR cash deposits will be deducted from the amount allocated to your ZAR wallet</li>
              <li il18>ChainEX does not allow cash deposits</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngTemplateOutlet="autoTrade"></ng-container>
  </div>
</div>

<ng-template #autoTrade>
  <div class="autotrade-border-box col-12 mt-4">
    <div class="col-12" *ngIf="!currencyInfo.autotrade; else autoTradeEnabled">
      <div class="row">
        <div class="col">
          <span class="autoTrade"><b i18n>Instant Trade on Deposit</b></span><br>
          <ng-container i18n>
            Using our automated trading functionality, you can set up your deposits to automatically buy or sell on a
            market of your choice.
          </ng-container>
        </div>
        <div class="col-12 col-md-6 col-lg-auto ng-star-inserted buttonContainer" *ngIf="!showAutoTrade">
          <button mat-raised-button class="col-12 col-md-auto" color="primary" i18n (click)="showAutoTrade = !showAutoTrade">
            ENABLE INSTANT TRADE
          </button>
        </div>
      </div>
      <div class="row mt-4" *ngIf="showAutoTrade" [formGroup]="autoTradeForm">
        <div class="col-12">
          <span class="text-bold" i18n>
            Complete the settings below to enable automatic buying or selling of funds received in this wallet
          </span><br>
          <ng-container i18n>
            Once the deposit clears I would like it to:
          </ng-container>
        </div>
        <div class="col-12 col-md-6 col-lg-8">
          <div class="row">
            <div class="col-12 col-md-6 col-lg-4">
              <mat-form-field class="w-100">
                <mat-select i18n-placeholder placeholder="Select Type"
                  [formControl]="autoTradeTypeControl" (selectionChange)="changeAutoTradeType()">
                  <mat-option *ngFor="let autoTradeType of autoTradeTypes" [value]="autoTradeType.value">
                    {{ autoTradeType.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <mat-form-field class="w-100">
                <mat-select i18n-placeholder placeholder="Select Coin" [formControl]="autoTradeMarketControl"
                  [disabled]="autoTradeMarketDisabled">
                  <mat-option *ngFor="let autoTradeMarket of autoTradeMarkets" [value]="autoTradeMarket.id">
                    <span *ngIf="autoTradeTypeControl.value === '0'">
                      {{ autoTradeMarket.coinName }}
                    </span>
                    <span *ngIf="autoTradeTypeControl.value === '1'">
                      {{ autoTradeMarket.exchangeName }}
                    </span>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-12 col-6 col-lg-4 ng-star-inserted buttonContainer text-right">
          <button class="ml-1" mat-raised-button color="accent" i18n (click)="resetAutoTrade()">
            CANCEL
          </button>
          <button class="ml-1" mat-raised-button color="primary" i18n (click)="enableAutoTrade()">
            ENABLE
          </button>
        </div>
      </div>
    </div>

    <ng-template #autoTradeEnabled>
      <div class="col-12">
        <div class="row">
          <div class="col">
            <span class="autoTrade"><b i18n>Instant Trade on Deposit</b></span><br>
            <ng-container i18n>
              Deposits received in this wallet will automatically:
            </ng-container>
            <div class="orange-text">
              <b>{{autoTradeType}} {{autoTradeMarket.coinCode}} ON {{autoTradeMarket.marketPair}}</b>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-auto ng-star-inserted buttonContainer">
            <button mat-raised-button class="col-12 col-md-auto" color="primary" i18n (click)="disableAutoTrade()">
              DISABLE INSTANT TRADE
            </button>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</ng-template>
