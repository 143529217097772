import {
  Component,
  OnDestroy,
  ViewChild,
  ElementRef,
  Input,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { MatStepper } from '@angular/material/stepper';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

import { SessionStorageService } from './../../core/services/session-storage.service';
import { ProfileService } from '../profile.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, ReplaySubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';

export interface Progress {
  edit: boolean;
  firstName: string;
  lastName: string;
  cellphone_attempt_count: string;
  max_cellphone_attempt_notification: string;
}

interface ProfileDetails {
  first_name: string;
  last_name: string;
  email: string;
  country_code: string;
  mobile: string;
}

@Component({
    /* encapsulation: ViewEncapsulation.None, */
    selector: 'app-verification-phone',
    templateUrl: './verification-phone.component.html',
    styleUrls: [ './verification-phone.component.scss']
  })
  export class VerificationPhoneComponent implements OnInit, OnDestroy {
    @Output() verificationNext: EventEmitter<any> = new EventEmitter();
    @Output() verificationCancel: EventEmitter<any> = new EventEmitter();
    @Output() showToastNotification: EventEmitter<any> = new EventEmitter();
    @Input() verificationData: any = {
      complianceOnly: false,
      kyc_attempts: 0,
      cellphone_attempt_count: 0,
      max_cellphone_attempt_notification: '',
      verifyResidence: false,
      profile: {}
    };

    level: number;
    step: number;
    edit: boolean;

    user_profile_form: FormGroup;
    verify_code_form: FormGroup;

    exchangeName: string = environment.config.EXCHANGE_NAME_L;

    profile: ProfileDetails = {
      first_name: '',
      last_name: '',
      email: '',
      country_code: '',
      mobile: ''
    };

    verification_code: string;

    countries: any = [{iso: '', phonecode: '0', id: -1, blocked: ''}];
    filteredCountries: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

    result_success: boolean;
    optExpired: boolean = false;
    attemptLimit: boolean;
    result_message: string;

    verification_level: number;

    hideFirstName: boolean;
    hideLastName: boolean;

    cellphone_attempt_count: number;

    minimumRequiredDigits: number = 9;

    countryFilterCtrl: FormControl = new FormControl();

    _onDestroy: Subject<void> = new Subject<void>();

    smsSent: boolean = false;
    smsTimer: number = 60 * 15;
    smsTimeLeftText: string = '';

    @ViewChild('stepper') stepper: MatStepper;
    @ViewChild('countrySelect') countrySelect: MatSelect;

    constructor(
      public dialog: MatDialog,
      private profile_Service: ProfileService,
      private formBuilder: FormBuilder,
      private sessionStorage: SessionStorageService,
    ) {

      this.profile_Service.getCountries({orderBy: 'iso'}).subscribe((response: any) => {
        if (response.response === 'success') {
          this.countries = response.data;
          const idx = this.countries.findIndex((d) => d.iso === 'ZA');
          if (idx > -1) {
            // remove (splice) ZA and add to front of countries array
            this.countries.unshift(this.countries.splice(idx, 1)[0]);
          }
          this.filter_country();
        }
      });

      let level: any = this.sessionStorage.get('PROFILE_VERIFICATION_LEVEL');
      if (level === '') {
        level = 0;
      } else {
        level = Number(level);
      }

      this.verification_level = level || 0;

      this.countryFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
        this.filter_country();
      });
    }

    ngOnInit(): void {
      this.edit = !!this.verificationData && !!this.verificationData.edit;
      if (!!this.verificationData &&
        !!this.verificationData.firstName &&
        this.verificationData.firstName !== '-' &&
        this.verificationData.firstName !== '') {
        this.profile.first_name = this.verificationData.firstName;
        this.hideFirstName = true;
      } else {
        this.profile.first_name = '';
        this.hideFirstName = false;
      }

      if (!!this.verificationData &&
        !!this.verificationData.lastName &&
        this.verificationData.lastName !== '-' &&
        this.verificationData.lastName !== '') {
        this.profile.last_name = this.verificationData.lastName;
        this.hideLastName = true;
      } else {
        this.profile.last_name = '';
        this.hideLastName = false;
      }

      if (!!this.verificationData &&
        !this.verificationData.cellphone_attempt_count) {
          this.cellphone_attempt_count = 0;
      } else {
        this.cellphone_attempt_count = Number(this.verificationData.cellphone_attempt_count);
      }

      this.user_profile_form = this.formBuilder.group({
        first_name: [ ''] ,
        last_name: [ '' ],
        country_code: [ '', Validators.required ],
          mobile_number: [ '', Validators.pattern('^(0[0-9]{9,9})|([0-9]{9,9})$') ]
        });

      if (!this.hideFirstName) {
        this.user_profile_form.controls.first_name.setValidators(Validators.required);
      }

      if (!this.hideLastName) {
        this.user_profile_form.controls.last_name.setValidators(Validators.required);
      }

      this.verify_code_form = this.formBuilder.group({
        verify_code: [ '', Validators.pattern('([0-9]{6,6})$') ]
      });

    }

    opened(e: any) {
      const element: ElementRef<HTMLElement> = this.countrySelect.panel;
      const host: ElementRef<HTMLElement> = this.countrySelect._elementRef;

      if (!!host.nativeElement && !!element && !!element.nativeElement) {
        let top: number = host.nativeElement.parentElement.getBoundingClientRect().top;

        top += 100;
        element.nativeElement.parentElement.style.top = top + 'px';
        element.nativeElement.parentElement.style.display = 'inline-block';
      }
    }

    cancel_click() {
      this.verificationCancel.emit();
    }

    filter_country() {
      if (!this.countries) {
        return;
      }
      let search = this.countryFilterCtrl.value;

      if (!search) {
        this.filteredCountries.next(this.countries.slice());
        return;
      } else {
        search = search.toLowerCase();
      }

      this.filteredCountries.next(
        this.countries.filter(country => country.nicename.toLowerCase().indexOf(search) > -1)
      );
    }

    ngOnDestroy() {
      this._onDestroy.next();
      this._onDestroy.complete();
    }

    country_code_change() {
      for (let i = 0; i < this.countries.length; i++) {
        if (this.countries[i].id === this.profile.country_code) {
          const oldRequirement = this.minimumRequiredDigits;
          this.minimumRequiredDigits = 11 - +this.countries[i].phonecode.length;
          if (oldRequirement !== this.minimumRequiredDigits) {
            this.user_profile_form.controls.mobile_number
              .setValidators([Validators.pattern('^(0[0-9]{' + this.minimumRequiredDigits +
              ',' + this.minimumRequiredDigits + '})|([0-9]{' + this.minimumRequiredDigits +
              ',' + this.minimumRequiredDigits + '})$')]);
          }
        }
      }
    }

    next_click(e: any) {
      this.user_profile_form.markAsTouched();
      if (this.user_profile_form.valid) {
        const data = {
          first_name: this.profile.first_name,
          last_name: this.profile.last_name,
          cellphone_number: this.profile.mobile,
          country_code: this.profile.country_code,
          cell_edit: 1
        };
        this.profile_Service.sendOtpToCellPhoneNumber(data).subscribe((resp: any) => {
          if (resp.response === 'success') {
            this.result_success = true;
            this.result_message = resp.reason;
            this.cellphone_attempt_count = resp.cellphone_attempts;
            if (this.cellphone_attempt_count === 2) {
              this.attemptLimit = true;
            }
            if (!!this.stepper) {
              this.showToastNotification.emit('OTP has been sent');
              this.stepper.next();
              this.smsSending();
            }
          } else {
            this.result_success = false;
            this.result_message = resp.reason;
            if (!!resp.failuretype) {
              this.attemptLimit = resp.failuretype.toLowerCase() === 'retries';
            }
          }
        });
      }
    }

    verify_click() {
      const data = {
        verification_code: this.verification_code,
      };

      this.profile_Service.verifyCellPhoneNumber(data).subscribe((resp: any) => {
        if (resp.response === 'success') {
          if (this.verification_level === 0) {
            this.sessionStorage.store('PROFILE_VERIFICATION_LEVEL', 1);
            this.sessionStorage.store('PROFILE_FIRST_NAME', this.profile.first_name);
            this.sessionStorage.store('PROFILE_LAST_NAME', this.profile.last_name);
          }
          this.showToastNotification.emit('Cellphone verified succesfully');
          this.verificationNext.emit();
        } else {
          if (resp.reason === 'VERIFYCELL_CODEEXPIRED') {
            this.optExpired = true;
          } else {
            this.optExpired = false;
          }
          this.result_success = false;
          this.result_message = resp.reason;
        }
      });
    }

    smsSending() {
      this.smsTimer = 60 * 15;
      this.smsSent = true;

      const interval = setInterval(() => {
        if (this.smsTimer > 0) {
          this.smsTimer--;

          const minutes = Math.floor(this.smsTimer / 60);
          const seconds = this.smsTimer % 60;
          this.smsTimeLeftText = minutes > 0 ? `${minutes}m ${seconds}s` : `${seconds}s`;
        }

        if (this.smsTimer === 0) {
          this.smsSent = false;
          clearInterval(interval);
        }
      }, 1000);
    }

    resend_click() {
      this.smsSending();
      if (this.profile.mobile[0] !== '0') {
        this.profile.mobile = '0' + this.profile.mobile;
      }

      const data = {
        cellphone_number: this.profile.mobile,
        country_code: this.profile.country_code,
        cell_edit: 1
      };

      this.profile_Service.sendOtpToCellPhoneNumber(data).subscribe((resp: any) => {
        if (resp.response === 'success') {
          this.result_success = true;
          this.optExpired = false;
          this.result_message = resp.resent_reason;
          this.cellphone_attempt_count = resp.cellphone_attempts;
        } else {
          this.result_success = false;
          this.result_message = resp.reason;

          if (!!resp.failuretype) {
            this.attemptLimit = resp.failuretype.toLowerCase() === 'retries';
          }
        }
      });
    }

}
